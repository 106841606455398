import { BLOCKS_CLIENT, BLOCKS_CLIENT_ETH, INFO_CLIENT, INFO_CLIENT_ETH } from 'config/constants/endpoints'
import { infoClientETH, infoClient, infoStableSwapClient } from 'utils/graphql'

import { ChainId } from '@pancakeswap/sdk'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  PCS_V2_START,
  TOKEN_BLACKLIST,
  BSC_TOKEN_WHITELIST,
  ETH_TOKEN_WHITELIST,
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'CORE' | 'BTCS' | 'LONG' | 'OEX' | 'BOAT' | 'QILIN'

// TODO: FIXME
export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  CORE: 'CORE',
  BTCS: 'tCORE',
  LONG: 'USDT',
  BOAT: 'BOAT',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  CORE: '',
  BTCS: '',
  BOAT: '',
}

export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ETH: PCS_ETH_START,
  CORE: 0,
  BTCS: 0,
  BOAT: 0,
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  CORE: ChainId.CORE,
  LONG: ChainId.LONG,
  BTCS: ChainId.BTCS,
  BOAT: ChainId.BOAT,
}

export const multiChainPaths = {
  [ChainId.BSC]: '',
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.LONG]: '/long',
  [ChainId.CORE]: '/core',
  [ChainId.BOAT]: '/boat',
  [ChainId.BTCS]: '/btcs',
}

// FIXME
export const multiChainQueryClient = {
  BSC: infoClient,
  ETH: infoClientETH,
  CORE: infoClient,
  BTCS: infoClient,
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT,
  ETH: INFO_CLIENT_ETH,
  CORE: INFO_CLIENT,
  BTCS: INFO_CLIENT,
}

export const multiChainScan = {
  BSC: 'BscScan',
  ETH: 'EtherScan',
  CORE: 'CoreScan',
  BTCS: 'BtcsScan',
  LONG: 'LongScan',
  BOAT: 'BoatScan',
}

export const multiChainTokenBlackList = {
  BSC: TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  CORE: TOKEN_BLACKLIST,
  BTCS: TOKEN_BLACKLIST,
  LONG: TOKEN_BLACKLIST,
  BOAT: TOKEN_BLACKLIST,
}

export const multiChainTokenWhiteList = {
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
  CORE: BSC_TOKEN_WHITELIST,
  BTCS: BSC_TOKEN_WHITELIST,
  LONG: BSC_TOKEN_WHITELIST,
  BOAT: BSC_TOKEN_WHITELIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
